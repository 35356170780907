<template>
  <div class="personal-area">
    <div class="personal-area__menu-block">
      <div class="personal-area__logo logo">
        <router-link to="/"><span>E</span>FINMARKET</router-link>
      </div>

      <div class="personal-area__menu-wrapper">
        <div class="personal-area__menu-content">
          <ul class="personal-area__menu">
            <li>
              <router-link
                class="personal-area__menu-item"
                :to="{ name: 'Reports' }"
              >
                <span class="personal-area__menu-icon">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16 0H2C0.9 0 0 0.9 0 2V9C0 10.1 0.9 11 2 11H16C17.1 11 18 10.1 18 9V2C18 0.9 17.1 0 16 0ZM16 6H12C12 7.62 10.62 9 9 9C7.38 9 6 7.62 6 6H2V2H16V6ZM12 13H18V16C18 17.1 17.1 18 16 18H2C0.9 18 0 17.1 0 16V13H6C6 14.66 7.34 16 9 16C10.66 16 12 14.66 12 13Z"
                      fill="black"
                    />
                  </svg>
                </span>
                Отчеты
              </router-link>
            </li>
            <li>
              <router-link
                class="personal-area__menu-item"
                :to="{ name: 'Subscribe' }"
              >
                <span class="personal-area__menu-icon">
                  <svg
                    width="24"
                    height="14"
                    viewBox="0 0 24 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.0002 2L16.5902 0.589996L10.2502 6.93L11.6602 8.34L18.0002 2ZM22.2402 0.589996L11.6602 11.17L7.48016 7L6.07016 8.41L11.6602 14L23.6602 2L22.2402 0.589996ZM0.410156 8.41L6.00016 14L7.41016 12.59L1.83016 7L0.410156 8.41Z"
                      fill="black"
                    />
                  </svg>
                </span>
                Подписка
              </router-link>
            </li>
            <li>
              <router-link
                class="personal-area__menu-item"
                :to="{ name: 'Profile' }"
              >
                <span class="personal-area__menu-icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12 4C7.58457 4 4 7.58457 4 12C4 16.4154 7.58457 20 12 20C16.4154 20 20 16.4154 20 12C20 7.58457 16.4154 4 12 4ZM2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12Z"
                      fill="black"
                    />
                    <path
                      d="M12 5C13.66 5 15 6.34 15 8C15 9.66 13.66 11 12 11C10.34 11 9 9.66 9 8C9 6.34 10.34 5 12 5ZM12 18.5C9.5 18.5 7.29 16.94 6 15C6.03 13.01 10 12 12 12C13.99 12 17.97 13.01 18 15C16.71 16.94 14.5 18.5 12 18.5Z"
                      fill="black"
                    />
                  </svg>
                </span>
                Профиль
              </router-link>
            </li>
            <li>
              <router-link
                class="personal-area__menu-item"
                :to="{ name: 'Documents' }"
              >
                <span class="personal-area__menu-icon">
                  <svg
                    width="20"
                    height="19"
                    viewBox="0 0 20 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12 4V2H8V4H12ZM2 6V17H18V6H2ZM18 4C19.11 4 20 4.89 20 6V17C20 18.11 19.11 19 18 19H2C0.89 19 0 18.11 0 17L0.00999999 6C0.00999999 4.89 0.89 4 2 4H6V2C6 0.89 6.89 0 8 0H12C13.11 0 14 0.89 14 2V4H18Z"
                      fill="black"
                    />
                  </svg>
                </span>
                Документы
              </router-link>
            </li>
            <li>
              <router-link
                class="personal-area__menu-item"
                :to="{ name: 'Feedback' }"
              >
                <span class="personal-area__menu-icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4.01 4H20V16H5.17157L4.00269 17.1689L4.01 4ZM2 22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2H4C2.9 2 2.01 2.9 2.01 4L2 22Z"
                      fill="black"
                    />
                    <rect x="6" y="6" width="12" height="2" fill="black" />
                    <rect x="6" y="9" width="12" height="2" fill="black" />
                    <rect x="6" y="12" width="8" height="2" fill="black" />
                  </svg>
                </span>
                Обратная связь
              </router-link>
            </li>
          </ul>
          <ul class="personal-area__menu">
            <li>
              <span @click="logout" class="personal-area__menu-item">
                <span class="personal-area__menu-icon">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.09 15.59L11.5 17L16.5 12L11.5 7L10.09 8.41L12.67 11H3V13H12.67L10.09 15.59ZM19 3H5C3.89 3 3 3.9 3 5V9H5V5H19V19H5V15H3V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z"
                      fill="black"
                    />
                  </svg>
                </span>
                Выход
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="personal-area__content">
      <h1 v-if="title" class="personal-area__title">
        {{ title }}
      </h1>
      <router-view />
    </div>
  </div>
</template>

<script>
import "./personal-area.scss";

export default {
  name: "PersonalArea",
  computed: {
    title() {
      return this.$route.meta.title;
    },
  },
  methods: {
    logout() {},
  },
};
</script>
